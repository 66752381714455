import type { RentReviewTableValue } from '@register/components/Review/Form/RentReviewTable'
import type { MarketingLevyReviewTable } from '../types'
import { IncreaseTableMethod } from '@register'

const toString = (val: number | null | undefined) =>
  typeof val === 'number' ? String(val) : undefined

export function convertMarketingLevyToReviewTable(
  value: MarketingLevyReviewTable,
): RentReviewTableValue {
  const data = {
    date: value.reviewDate ?? undefined,
    method: convertMethodToTable(value.method),
    fixedPercent: toString(value.fixedPercentage),
    fixedAmount: toString(value.fixedAmount),
    cpiPercent: toString(value.cpiPercent),
    cpiCap: toString(value.cpiCap),
    cpiCollar: toString(value.cpiCollar),
    marketReviewDate: value.marketReviewDate ?? undefined,
    marketReviewCap: toString(value.marketReviewCap),
    marketReviewCollar: toString(value.marketReviewCollar),
    // marketReviewAmount: toString(value.marketReviewAmount),
    greaterOf: toString(value.greaterOf),
    lesserOf: toString(value.lesserOf),
    value: (value.value as string | null) ?? undefined,
    amount: value.amount ?? undefined,
    duplicate: value.__duplicate as boolean | undefined,
  }

  Object.defineProperty(data, '__id', {
    enumerable: false,
    value: value.__id,
  })

  return data
}

function convertMethodToTable(
  method:
    | 'FixAmount'
    | 'Percentage'
    // Manual increase
    | 'FixedAmount'
    | 'FixedPercentage'
    | 'Cpi'
    | 'CpiPlus'
    | 'Market'
    | null
    | undefined,
) {
  switch (method) {
    case 'FixedAmount':
      return IncreaseTableMethod.FIXED_AMOUNT
    case 'Percentage': {
      return 'Percentage'
    }
    // Manual increase
    case 'FixAmount':
      return IncreaseTableMethod.FIXED_AMOUNT
    case 'FixedPercentage':
      return IncreaseTableMethod.FIXED_PERCENT
    case 'Cpi':
      return IncreaseTableMethod.CPI
    case 'CpiPlus':
      return IncreaseTableMethod.CPI_PLUS
    case 'Market':
      return IncreaseTableMethod.MARKET
    default:
      return undefined
  }
}
